import React, { useState, useRef } from 'react';
import { StyleSheet, View} from 'react-native';
import { Icon } from 'react-native-elements';
import Select, { components } from 'react-select';

import Input from '../components/Input';

const Dropdown = (props) => {

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const selectRef = useRef();

  const {
    blurOnSubmit = false,
    data,
    onChange,
    value
  } = props;

  return (
    <Select
      ref={selectRef}
      options={data}
      components={{
        SelectContainer: ({ children, ...props }) => {
          return (
            <View style={{width: '48%', position: 'relative', zIndex: 1000}}>
              <View style={{position: 'absolute', left: 0, bottom: 0, right: 0}}>
                <components.SelectContainer {...props}>
                  {children}
                </components.SelectContainer>
              </View>
              <Input
                label="State"
                value={value}
                inputStyle={styles.inputStyle}
                disabled
                disabledInputStyle={styles.disabledInputStyle}
                required
                containerStyle={styles.inputContainerCompact}
                rightIcon={<Icon name="caretdown" type="antdesign" color="#999999" size={17}/>}
                onFocus={() => {selectRef.current.focus()}}
                blurOnSubmit={blurOnSubmit}
              />
            </View>
          );
        }
      }}
      onChange={(data) => { 
        setMenuIsOpen(false);
        onChange(data);
      }}
      onBlur={() => {
        setMenuIsOpen(false);
      }}
      onFocus={() => {
        setMenuIsOpen(true);
      }}
      onMenuOpen={()=>{console.log("MENU OPENING")}}
      openMenuOnClick={true}
      menuIsOpen={menuIsOpen}
      captureMenuScroll={true}
      maxMenuHeight="100px"
      styles={{
        control: (provided) => ({
          ...provided,
          visibility: 'hidden'
        }),
        menu: (provided) => ({
          ...provided,
          backgroundColor: '#FFFFFF',
          zIndex: 1000
        }),
        option: (provided, state) => {
          const { value, selectProps } = state;
          const isSelected = value === selectProps.value?.value;

          return {
            ...provided,
            color: isSelected ? '#000000' : '#999999',
            backgroundColor: isSelected ? '#e3e3e3' : 'transparent',
            fontFamily: 'helvetica-neue-medium'
          }
        }
      }}
    />
  )
}

const styles = StyleSheet.create({
  inputContainerCompact: {
    width: '100%',
    backgroundColor: '#FFFFFF'
  },
  inputStyle: {
    fontSize: 16,
    width: '100%',
    position: 'relative'
  },
  disabledInputStyle: {
    color: '#999999',
    opacity: 1
  }
});

export default Dropdown;