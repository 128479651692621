import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Icon } from 'react-native-elements';
import { useFormikContext } from 'formik';

const OrderSuccess = () => {

  const { values } = useFormikContext();

  return (
    <View style={styles.container}>
      <View style={{flex: 1}}>
        <View style={{paddingTop: '30%'}}>
          <Icon
            name="like2"
            type="antdesign"
            color="#00A5FF"
            size={72}
          />
          <Text style={{fontSize: 17, color: '#333333', lineHeight: 22, letterSpacing: -0.41, fontFamily: 'helvetica-neue-medium', textAlign: 'center', paddingTop: 15}}>
            {`Thank you for your purchase. Confirmation of your order has been sent to ${values.customer.email}.  Please see ${values.userFirstName} for delivery information.`}
          </Text>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    paddingTop: 15
  },
  text: {
    color: "#8E8E93",
    fontSize: 16,
    letterSpacing: -0.38,
    lineHeight: 22,
    paddingVertical: 1
  }
})

export default OrderSuccess;