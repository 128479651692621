import { STAGE } from '../constants';

const DOMAIN = STAGE === 'dev' ? 'https://rj2y4j37f4.execute-api.us-east-2.amazonaws.com/dev' : 'https://7iqdmbnsp6.execute-api.us-east-2.amazonaws.com/prod';
const ADMIN_DOMAIN = STAGE === 'dev' ? 'https://1bqvo9fs4i.execute-api.us-west-1.amazonaws.com/dev' : 'https://1bqvo9fs4i.execute-api.us-west-1.amazonaws.com/prod';

export const getMembership = async (user, campaign) => {
  try {
    const response = await fetch(`${DOMAIN}/memberships/${encodeURIComponent(`${user}#${campaign}`)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    const res = await response.json();

    if (response.ok) {
      return res;
    } else {
      throw new Error(res.error); 
    }

  } catch (err) {
    throw err;
  }
}