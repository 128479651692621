import React, { useState } from 'react';
import { StyleSheet, Text, View} from 'react-native';
import { Field } from 'formik';

import Dropdown from '../components/Dropdown';
import Input from '../components/Input';

import { STATES } from '../constants';

const CustomerDetailsForm = () => {

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  return (
    <View style={styles.container}>
      <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingVertical: 5}}>
        <Text style={{color: '#000000', fontSize: 17, lineHeight: 22, letterSpacing: -0.41, fontFamily: 'helvetica-neue-bold'}}>Your Details</Text>
      </View>
      <Field name="customer.firstName">
        {({ field, form, meta }) => (
          <Input
            name="firstName"
            label="First Name"
            required
            value={field.value}
            error={meta.error}
            blurOnSubmit={false}
            returnKeyType="next"
            containerStyle={styles.inputContainer}
            onChange={(value, name)=>{
              form.setFieldValue(field.name, value.nativeEvent.text);
            }}
          />
        )}
      </Field>
      <Field name="customer.lastName">
        {({ field, form, meta }) => (
          <Input
            name="lastName"
            label="Last Name"
            required
            value={field.value}
            error={meta.error}
            blurOnSubmit={false}
            returnKeyType="next"
            containerStyle={styles.inputContainer}
            onChange={(value, name)=>{
              form.setFieldValue(field.name, value.nativeEvent.text);
            }}
          />
        )}
      </Field>
      <Field name="customer.email">
        {({ field, form, meta }) => (
          <Input
            name="email"
            label="Email"
            required
            value={field.value}
            error={meta.error}
            blurOnSubmit={false}
            returnKeyType="next"
            containerStyle={styles.inputContainer}
            onChange={(value, name)=>{
              form.setFieldValue(field.name, value.nativeEvent.text);
            }}
          />
        )}
      </Field>
      <Field name="customer.phone">
        {({ field, form, meta }) => (
          <Input
            name="phone"
            label="Phone Number"
            required
            value={field.value}
            error={meta.error}
            blurOnSubmit={false}
            returnKeyType="next"
            containerStyle={styles.inputContainer}
            onChange={(value, name)=>{
              form.setFieldValue(field.name, value.nativeEvent.text);
            }}
          />
        )}
      </Field>
      <Field name="customer.address">
        {({ field, form, meta }) => (
          <Input
            name="address"
            label="Address"
            required
            value={field.value}
            error={meta.error}
            blurOnSubmit={false}
            returnKeyType="next"
            containerStyle={styles.inputContainer}
            onChange={(value, name)=>{
              form.setFieldValue(field.name, value.nativeEvent.text);
            }}
          />
        )}
      </Field>
      <View style={[styles.inputRow, styles.inputContainer]}>
        <Field name="customer.city">
          {({ field, form, meta }) => (
            <Input
              name="city"
              label="City"
              required
              value={field.value}
              error={meta.error}
              blurOnSubmit={false}
              returnKeyType="next"
              containerStyle={styles.inputContainerCompact}
              onChange={(value, name)=>{
                form.setFieldValue(field.name, value.nativeEvent.text);
              }}
              inputStyle={{width: '100%'}}
            />
          )}
        </Field>
        <Field name="customer.state">
          {({ field, form }) => (
            <Dropdown
              data={STATES}
              onChange={({ value }) => {
                form.setFieldValue(field.name, value)
              }}
              value={field.value}
              openMenuOnClick={true}
              blurOnSubmit={false}
            />
          )}
        </Field>
      </View>
      <View style={[styles.inputRow, styles.inputContainer, { zIndex: -1 }]}>
        <Field name="customer.zipCode">
          {({ field, form, meta }) => (
            <Input
              name="zipCode"
              label="Zip"
              required
              value={field.value}
              error={meta.error}
              blurOnSubmit={false}
              returnKeyType="next"
              containerStyle={styles.inputContainerCompact}
              inputStyle={{width: '100%'}}
              onChange={(value, name)=>{
                form.setFieldValue(field.name, value.nativeEvent.text);
              }}
            />
          )}
        </Field>
        <View style={styles.inputContainerCompact}>
          <Text style={styles.textTip}>*Required Field</Text>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    paddingTop: 15
  },
  btnContainer: {
    borderRadius: 2,
    paddingVertical: 11,
    width: '100%'
  },
  btnStyle: {
    paddingVertical: 15
  },
  btnLabel: {
    fontSize: 15,
    color: '#FFFFFF',
    letterSpacing: -0.36,
    lineHeight: 22
  },
  inputContainer: {
    marginVertical: 15
  },
  inputContainerCompact: {
    width: '48%'
  },
  inputRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
  },
  textTip: {
    color: '#999999',
    fontSize: 11,
    lineHeight: 13,
    letterSpacing: 0.07,
    fontFamily: 'sf-pro-text-regular'
  }
});

export default CustomerDetailsForm;