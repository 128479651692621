import React from 'react';
import { Dimensions, StyleSheet, Text, View } from 'react-native';
import { ListItem } from 'react-native-elements';

import QuantityPicker from './QuantityPicker';

const HEIGHT = Dimensions.get('window').height;
const WIDTH = Dimensions.get('window').width;

const ProductCard = (props) => {

  const {
    handleChange,
    name,
    quantity,
    id,
    pickerStyle = {},
    titleStyle = {},
    containerStyle = {}
  } = props;

  return (
    <ListItem
      title={name}
      titleStyle={[styles.menuItemTitle, titleStyle]}
      containerStyle={[styles.menuItemContainer, containerStyle]}
      rightElement={() => {
        return <QuantityPicker handleChange={handleChange} quantity={quantity} name={id} containerStyle={pickerStyle}/>
      }}
      bottomDivider
    />
  );
}

const styles = StyleSheet.create({
  menuContainer: {
    flex: 1,
    paddingTop: 10,
    paddingLeft: WIDTH * 0.05
  },
  menuList: {
    flex: 1
  },
  menuDivider: {
    backgroundColor: '#C7C7CC'
  },
  menuItemContainer: {
    paddingVertical: 10,
    paddingHorizontal: 0,
    margin: StyleSheet.hairlineWidth
  },
  menuItemTitle: {
    color: "#333333",
    fontSize: 16,
    letterSpacing: -0.38,
    lineHeight: 22
  },
  text: {
    color: "#8E8E93",
    fontSize: 16,
    letterSpacing: -0.38,
    lineHeight: 22,
    paddingVertical: 1
  },
  capitalize: {
    textTransform: 'capitalize'
  }
})

export default ProductCard;