import React from 'react';
import { Dimensions, StyleSheet, Text, View } from 'react-native';

const HEIGHT = Dimensions.get('window').height;
const WIDTH = Dimensions.get('window').width;

const Header = (props) => {

  const {
    rightComponent,
    leftComponent,
    title,
    hasShadow = false,
    containerStyle= {},
    titleStyle= {},
    titleContainerStyle = {}
  } = props;

  return (
    <View style={hasShadow ? [style.wrapper, containerStyle, style.shadow] : [style.wrapper, containerStyle]}>
      <View style={style.container}>
        <View style={style.headerBtnContainer}>
          { leftComponent || <View></View>}
          { rightComponent || <View></View>}
        </View>
        <View style={[style.headerTitleContainer, titleContainerStyle]}>
          <Text style={[style.headerTitleText, titleStyle]}>{title}</Text>
        </View>
      </View>
    </View>
  );
}

const style = StyleSheet.create({
  wrapper: {
    // height: HEIGHT * 0.15,
    // flex: 0
    display: 'inline-block'
  },
  shadow: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.20,
    shadowRadius: 1.41,
    backgroundColor : "gray",
    elevation: 3
  },
  container: {
    paddingHorizontal: WIDTH * 0.05,
    // height: HEIGHT * 0.15,
    backgroundColor: '#FFFFFF',
    height: '100%'
  },
  headerBtnContainer: {
    // height: '46%',
    height: HEIGHT * 0.075,
    backgroundColor: 'transparent',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
    flexDirection: 'row'
  },
  headerBtnText: {
    color: '#00A5FF',
    fontFamily: 'helvetica-neue-medium',
    fontWeight: 'normal',
    fontSize: 17
  },
  headerTitleContainer: {
    flex: 1,
    justifyContent: 'center',
    // paddingHorizontal: WIDTH * 0.03
  },
  headerTitleText: {
    fontFamily: 'helvetica-condensed',
    fontSize: 30,
    letterSpacing: 1
  },
})

export default Header;