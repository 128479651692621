import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Icon } from 'react-native-elements';
import { Field } from 'formik';
import creditCardType from 'credit-card-type';

import CheckBox from './CheckBox';
import Input from '../components/Input';

function _formatCardType(cardNumber) {
  const cardType = creditCardType(cardNumber);
  let name;

  if (cardNumber === undefined || cardNumber === null || cardNumber === '') {
    return <View></View>;
  }

  switch(cardType[0]?.type) {
    case 'visa':
      name = 'cc-visa';
      break;
    case 'mastercard':
      name = 'cc-mastercard';
      break;
    case 'american-express':
      name = 'cc-amex';
      break;
    case 'discover':
      name = 'cc-discover';
      break;
    default:
      name = '';
  }

  return name ? <Icon name={name} type="font-awesome" /> : <View></View>
}

const PaymentDetailsForm = () => {
  return (
    <View style={styles.container}>
      <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingVertical: 5}}>
        <Text style={{color: '#000000', fontSize: 17, lineHeight: 22, letterSpacing: -0.41, fontFamily: 'helvetica-neue-bold'}}>Payment Details</Text>
      </View>
      <Field name="cardNumber">
        {({ field, form, meta }) => (
          <Input
            name="cardNumber"
            label="Card Number"
            required
            value={field.value}
            error={meta.error}
            keyboardType="number-pad"
            returnKeyType="next"
            rightIcon={_formatCardType(field.value)}
            containerStyle={styles.inputContainer}
            onChange={(value, name)=>{
              form.setFieldValue(name, value.nativeEvent.text);
            }}
          />
        )}
      </Field>
      <View style={[styles.rowContainer, styles.inputContainer]}>
        <Field name="expiry">
          {({ field, form, meta }) => (
            <Input
              name="expiry"
              label="MMYY"
              required
              containerStyle={styles.inputContainerCompact}
              value={field.value}
              error={meta.error}
              keyboardType="number-pad"
              returnKeyType="next"
              blurOnSubmit={false}
              onChange={(value, name)=>{
                form.setFieldValue(name, value.nativeEvent.text);
              }}
            />
          )}
        </Field>
        <Field name="cvc">
          {({ field, form, meta }) => (
            <Input
              name="cvc"
              label="CVC"
              required
              value={field.value}
              error={meta.error}
              blurOnSubmit={false}
              returnKeyType="next"
              containerStyle={styles.inputContainerCompact}
              onChange={(value, name)=>{
                form.setFieldValue(name, value.nativeEvent.text);
              }}
            />
          )}
        </Field>
      </View>
      <View style={[styles.notifyContainer, styles.inputContainer]}>
        <Field name="didNotify">
          {({ field, form, meta }) => (
            <CheckBox
              checked={field.value}
              checkedIcon="check"
              checkedColor="#FFFFFF"
              uncheckedColor="#FFFFFF"
              uncheckedIcon="close"
              iconType="antdesign"
              containerStyle={field.value ? styles.isNotified : styles.isNotNotified}
              size={8}
              onPress={() => {
                form.setFieldValue('didNotify', !field.value);
              }}
              error={meta.error}
            />
          )}
        </Field>
        <Text style={styles.text}>I accept the $1 service charge per unit purchased</Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    paddingTop: 15
  },
  header: {
    fontSize: 17,
    lineHeight: 22,
    letterSpacing: -0.41,
    fontFamily: 'helvetica-neue',
    fontWeight: 'bold',
    color: '#000000',
    paddingBottom: 10
  },
  rowContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  notifyContainer: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center'
  },
  isNotified: {
    backgroundColor: '#00A5FF',
    borderWidth: 1,
    borderColor: '#00A5FF',
    marginLeft: 0
  },
  isNotNotified: {
    backgroundColor: '#FFFFFF',
    borderWidth: 1,
    borderColor: '#00A5FF',
    marginLeft: 0
  },
  text: {
    flex: 1,
    flexWrap: 'wrap',
    fontSize: 12,
    lineHeight: 16,
    color: '#000000',
    fontFamily: 'helvetica-neue-regular',
    fontWeight: 'bold'
  },
  inputContainer: {
    marginVertical: 15
  },
  inputContainerCompact: {
    width: '46%'
  }
})

export default PaymentDetailsForm;