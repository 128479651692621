export const parseQueryStringParams = (queryString) => {
  const params = {};

  decodeURIComponent(queryString).slice(1).split('&').reduce((total, param) => {
    const pair = param.split('=');
    const key = pair[0];
    const value = pair[1];

    total[key] = value;

    return total;
  }, params);

  return params;
}

export const getCurrentPosition = (options) => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options);
  });
}

export const getCurrentAddress = async () => {
    const { geolocation } = navigator;
    
    if (!geolocation) {
      console.log("GEOLOCATION NOT AVAILABLE");
      return;
    }

    let location = await getCurrentPosition({enableHighAccuracy: true});

    const response = await fetch('https://maps.googleapis.com/maps/api/geocode/json?address=' + location.coords.latitude + ',' + location.coords.longitude + '&key=' + 'AIzaSyDHgwtPD8Z_xBJgxFQ0exHtC0_XufdpwJw')
    
    const geocode = await response.json();

    const components = geocode.results[0].address_components;

    const address = {
      coords: {
        lat: location.coords.latitude,
        lng: location.coords.longitude
      } 
    };

    components.reduce((total, component) => {

      const { types, short_name } = component;
      const partsMap = {
        street_number: 'address',
        route: 'address',
        locality: 'city',
        administrative_area_level_1: 'state',
        postal_code: 'zipCode'
      };

      types.forEach(type => {
        if (partsMap.hasOwnProperty(type)) {
          const part = partsMap[type];

          if (part === 'address' && total.hasOwnProperty(part)) {
            total[part] += ` ${short_name}`
          } else {
            total[part] = short_name;
          }
        }
      });

      return total;
    }, address);

    return address;
}